import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

const PhoneNumberFormat = ({ inputRef, onChange, ...other }) => {
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        const value = values.value || '';
        onChange({
          target: {
            name: other.name,
            value: value.toString(),
          },
        });
      }}
      format="###########"
      mask="_"
      allowEmptyFormatting
      isAllowed={(values) => {
        const { value } = values;
        return true; // Akzeptiere alle Eingaben
      }}
    />
  );
};

function PhoneInput(props) {
  const { value, onChange, label, required, error, helperText, name } = props;

  return (
    <TextField
      value={value || ''}
      onChange={onChange}
      label={label}
      required={required}
      error={error}
      helperText={helperText}
      name={name}
      variant="outlined"
      fullWidth
      size="small"
      InputProps={{
        inputComponent: PhoneNumberFormat,
      }}
      sx={{
        width: '100%',
        '& .MuiOutlinedInput-root': {
          height: '56px',
        },
        '& .MuiOutlinedInput-input': {
          height: '24px',
        },
      }}
    />
  );
}

export default PhoneInput;
